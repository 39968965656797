<template>
  <div class="footer">
    <div class="logo">
      <img src="../../assets/icon/logo.png" />
    </div>
    <div class="information">
      <div class="item">
        <div class="title">{{ $t("footer.aboutUs") }}</div>
        <div class="link" @click="$router.push('/ConnectUs')">
          {{ $t("nav.contact_us") }}
        </div>
        <div class="link" v-for="val in cate1" :key="val.articleId" @click="$router.push('/article?id=' + val.articleId)">
          {{ val.title }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t("footer.myAccount") }}</div>
        <div class="link" @click="$router.push('/OrderList')">
          {{ $t("userTab.order") }}
        </div>
        <div class="link" @click="$router.push('/Address')">
          {{ $t("userTab.address") }}
        </div>
        <div class="link" @click="$router.push('/Collect')">{{ $t("userTab.wish") }}</div>
        <div class="link" @click="$router.push('/Information')">
          {{ $t("userTab.setting") }}
        </div>
        <!-- <div class="link" @click="$router.push('/OrderList')">{{$t('userTab.history')}}</div> -->
        <!-- <div class="link" @click="$router.push('/OrderList')">{{$t('userTab.coupon')}}</div> -->
      </div>
      <div class="item">
        <div class="title">{{ $t("footer.information") }}</div>
        <div class="link" v-for="val in cate2" :key="val.articleId" @click="$router.push('/article?id=' + val.articleId)">
          {{ val.title }}
        </div>
      </div>
      <div class="subscribe">
        <div class="title">Suscríbete a nuestra Newsletter</div>
        <div class="line"></div>
        <div class="fill">
          <span>Rellena tus datos aquí</span>
          <i @click="$router.push('/ConnectUs')" class="el-icon-right"></i>
        </div>
        <div class="line"></div>
        <div class="social">
          <img src="../../assets/icon/facebook.png" @click="handleFaceBook()" />
          <img src="../../assets/icon/camera.png" @click="handleCamera()" />
          <!-- <img src="../../assets/icon/twitter.png"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/request.js";
export default {
  name: "Footer",
  data() {
    return {
      cate1: [],
      cate2: [],
    };
  },
  created() {
    this.getBottomCate();
  },
  methods: {
    getBottomCate() {
      Api.AboutUs.article({
        page: 1,
        pageLimit: 10,
        articleMenuId: 5,
      }).then((res) => {
        this.cate1 = res.data.data.list;
      });
      Api.AboutUs.article({
        page: 1,
        pageLimit: 10,
        articleMenuId: 1,
      }).then((res) => {
        this.cate2 = res.data.data.list;
      });
    },
    handleFaceBook() {
      window.location.href = "https://www.facebook.com/GIA-MIA-103239429025027";
    },
    handleCamera() {
      window.location.href = 'https://www.instagram.com/giamiamarbella/'; //"https://www.instagram.com/newness_kids/";
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  background: #f7f7f7;
  padding: 5vw;
  overflow: hidden;
  .logo {
    & > img {
      width: 70px;
      // height: 34px;
    }
  }
  .information {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
    .item {
      flex: 1;
      div {
        font-size: 16px;
        color: #000000;
        line-height: 19px;
        margin-bottom: 17px;
        font-family: GillSans;
        &.title {
          font-weight: 600;
        }
        &.link {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .subscribe {
      width: 100%;
      .title {
        font-size: 30px;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        margin-bottom: 18px;
        margin-top: 5vw;
      }
      .line {
        height: 1px;
        background: #000000;
      }
      .fill {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        span {
          font-size: 14px;
          font-family: GillSans;
          color: #000000;
        }
        i {
          font-size: 20px;
          color: #c7c7c7;
          cursor: pointer;
          &:hover {
            animation: move linear 0.5s;
          }
        }
        @keyframes move {
          30% {
            transform: translateX(5px);
          }
          60% {
            transform: translateX(0px);
          }
          90% {
            transform: translateX(5px);
          }
          100% {
            transform: translateX(0);
          }
        }
      }
      .social {
        margin-top: 24px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
